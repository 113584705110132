<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  to: string
  imgLeft?: string
  imgRight?: string
}>()

defineSlots<{
  default: () => any
}>()

const banner = ref<HTMLAnchorElement | null>(null)
const isSticky = ref(false)

useEventListener('scroll', () => {
  const stickyOffset = banner.value?.offsetTop || 0
  isSticky.value = window.scrollY > stickyOffset
})
</script>

<template>
  <NuxtLink
    ref="banner"
    class="w-full flex flex-row items-center justify-center gap-2 bg-[#FBEDCC] p-3 text-5 font-bold"
    :to="to"
    :class="{ 'sticky top-0 z-200': isSticky }"
  >
    <NuxtImg v-if="imgLeft" :src="imgLeft" alt="Left Icon" class="h-7 w-7" :width="40" :height="40" />
    <p class="text-center text-void font-gravity">
      <MDCSlot name="default" unwrap="p" />
    </p>
    <NuxtImg v-if="imgRight" :src="imgRight" alt="Right Icon" class="h-6 w-6" :width="40" :height="40" />
  </NuxtLink>
</template>

<style scoped>
/* Add any additional styles for the sticky banner if necessary */
</style>
